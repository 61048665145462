
















































































































































































































import api from "@/api/index"; //ABP API接口
import {Vue, Component, Ref} from "vue-property-decorator";
import {
  BbsPostDto,
  DataDictionaryDtoPagedResultDto,
  FundDto,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";

@Component({
  name: "FundMaintainList",
  components: {
    PagedTableView,
    AbSelect,
  },
  filters: {
    formatType(type: string, typeList: any[]) {
      let result = "";
      typeList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
  },
})
export default class FundMaintainList extends Vue {
  tableItems: FundDto[] = [];
  typeList: any = [];
  childList: any = [];

  queryForm: any = {
    name: "",
    type: "",
    fundCategory: "",
  };

  created() {
    this.fetchEnumType();
    this.fetchDataDictionary();
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "FundType",
      })
      .then((res) => {
        this.typeList = res;
      });
  }

  async fetchDataDictionary() {
    await api.dataDictionary
      .getDataDictionaryListByKey({key: "FundCategory", maxResultCount: 1000})
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.childList = res.items;
      });
  }

  // 获取表数据
  fetchData(params: any) {
    return api.fund.getAll(params);
  }

  // 新建
  handleCreate() {
    this.$router.push({name: "fundMaintainCreate"});
  }

  handleMenu($row: any) {
    switch ($row.type) {
      case "detail":
        this.jumpDetail($row.item);
        break;
      case "edit":
        this.handleEdit($row.item);
        break;
      case "editApply":
        this.handleEditApply($row.item);
        break;
      case "delete":
        this.handleDelete($row.item.id);
        break;
      case "stickyPost":
        this.handleStickyPost($row.item.id);
        break;
      case "notStickyPost":
        this.handleNotStickyPost($row.item.id);
        break;
    }
  }

  handleMenuRight($row: any) {
    switch ($row.type) {
      case "project":
        this.handleProject($row.item);
        break;
      case "news":
        this.handleNews($row.item);
        break;
      case "start":
        this.handleEnableFundRaising($row.item.id);
        break;
      case "stop":
        this.handleDisableFundRaising($row.item.id);
        break;
      case "moneyFormEdit":
        this.handleMoneyFormEdit($row.item);
        break;
      case "donate":
        this.handleDonate($row.item.id);
        break;
      case "OfficialWebsiteView":
        this.handleOfficialWebsiteDisplay($row.item.id);
        break;
      case "OfficialWebsiteHide":
        this.handleOfficialWebsiteDisplay($row.item.id);
        break;
    }
  }

  // 查看详情
  jumpDetail(row: FundDto) {
    this.$router.push({
      name: "fundMaintainDetail",
      params: {id: row.id!.toString()},
    });
  }

  // 编辑
  handleEdit(row: FundDto) {
    this.$router.push({
      name: "fundMaintainEdit",
      params: {id: row.id!.toString()},
    });
  }

  // 修改申请
  handleEditApply(row: FundDto) {
    this.$router.push({
      name: "fundMaintainApplyEdit",
      params: {id: row.id!.toString()},
    });
  }

  // 基金项目
  handleProject(row: FundDto) {
    this.$router.push({
      name: "fundProjectList",
      params: {fundId: row.id!.toString()},
    });
  }

  // 相关报道
  handleNews(row: FundDto) {
    this.$router.push({
      name: "content-list-other",
      params: {
        fundId: row.id!.toString(),
        fundProjectId: "0",
      },
    });
  }

  // 删除
  async handleDelete(id: number) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.fund.delete({id: id}).then((res) => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      });
    });
  }

  // 开启筹款
  handleEnableFundRaising(id: number) {
    this.$confirm("确定要开启筹款吗?", "提示").then(async () => {
      await api.fund.enableFundRaising({fundId: id}).then((res) => {
        this.$message({
          type: "success",
          message: "操作成功!",
        });
      });
    });
  }

  // 关闭筹款
  handleDisableFundRaising(id: number) {
    this.$confirm("确定要关闭筹款吗?", "提示").then(async () => {
      await api.fund.disableFundRaising({fundId: id}).then((res) => {
        this.$message({
          type: "success",
          message: "操作成功!",
        });
      });
    });
  }

  handleMoneyFormEdit(row: FundDto) {
    this.$router.push({
      name: "fundMoneyFormEdit",
      params: {id: row.id!.toString()},
    });
  }

  handleStickyPost(fundId: number) {
    this.$confirm("您确定要置顶吗？", "提示").then(() => {
      api.fund.stickyPost({body: {id: fundId}}).then(() => {
        this.$message.success("置顶成功!");
        this.fetchData(this.queryForm);
        (this as any).$bus.$emit("refresh-data");
      });
    });
  }

  handleOfficialWebsiteDisplay(fundId: number) {
    this.$confirm("您确定要操作吗？", "提示").then(() => {
      api.fund.setOfficialWebsiteDisplay({body: {id: fundId}})
        .then(() => {
          this.$message.success("操作成功!");
          this.fetchData(this.queryForm);

          (this as any).$bus.$emit("refresh-data");
        });
    });
  }

  handleNotStickyPost(fundId: number) {
    this.$confirm("您确定要取消置顶吗？", "提示").then(() => {
      api.fund.stickyPost({body: {id: fundId}}).then(() => {
        this.$message.success("取消成功!");
        (this as any).$bus.$emit("refresh-data");
        this.fetchData(this.queryForm);
      });
    });
  }
}
